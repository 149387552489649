
export enum CustomerAgentRole {
    user = "user",
    superUser = "superUser",
    adminUser = "adminUser",
}
export enum InventoryItemStatus {
    available = "available",
    rentedOut = "rentedOut",
    inspection = "inspection",
    pendingRecycle = "pendingRecycle",
    removed = "removed",
    sold = "sold",
    picked = "picked",
    inTransit = "inTransit",
    underProduction = "underProduction",
    recycled = "recycled",
}
export enum InventoryCountingSessionStatus {
    started = "started",
    completed = "completed",
}
export enum DamageType {
    screwHoles = "screwHoles",
    rippedEars = "rippedEars",
    doublePatch = "doublePatch",
    holeOrTear = "holeOrTear",
    other = "other",
    hookStrap = "hookStrap",
    webbing = "webbing",
    hooks = "hooks",
    lostRfid = "lostRfid",
    completelyDamaged = "completelyDamaged",
    badQualityOfMaterial = "badQualityOfMaterial",
}
export enum InventoryContainerType {
    maintenance = "maintenance",
    repair = "repair",
    production = "production",
    other = "other",
}
export enum InventoryMovementType {
    rentalOrder = "rentalOrder",
    salesOrder = "salesOrder",
    purchaseOrder = "purchaseOrder",
    container = "container",
    receive = "receive",
}
export enum AccountingSystemInvoiceStatus {
    pendingExport = "pendingExport",
    exportSucceeded = "exportSucceeded",
    exportFailed = "exportFailed",
}
export enum MaintenanceActivityType {
    repair = "repair",
    cleaning = "cleaning",
    recycle = "recycle",
}
export enum NoteType {
    info = "info",
    alert = "alert",
}
export enum OrderType {
    rentalOrder = "rentalOrder",
    salesOrder = "salesOrder",
}
export enum PurchaseOrderStatus {
    new = "new",
    confirmed = "confirmed",
    received = "received",
    canceled = "canceled",
}
export enum RentalOrderStatus {
    new = "new",
    confirmed = "confirmed",
    fulfilled = "fulfilled",
    canceled = "canceled",
    completed = "completed",
}
export enum SalesOrderStatus {
    new = "new",
    confirmed = "confirmed",
    fulfilled = "fulfilled",
    canceled = "canceled",
    invoiced = "invoiced",
}
export enum PickListStatus {
    new = "new",
    started = "started",
    completed = "completed",
    canceled = "canceled",
}
export enum RentalStatus {
    started = "started",
    ended = "ended",
    canceled = "canceled",
}
export enum ReportParameterType {
    calendarMonth = "calendarMonth",
    order = "order",
    customer = "customer",
}
export enum ServiceType {
    order = "order",
    maintenanceActivity = "maintenanceActivity",
}
